<template>
  <v-row justify="center">
    <v-dialog v-model="shower" fullscreen hide-overlay transition="dialog-bottom-transition" scrollable>
      <!-- <v-card >  -->
      <v-card class="elevation-0" :color="$vuetify.theme.dark ? '#333333' : '#f5faff'">
      <v-toolbar elevation="0" v-if="resolutionScreen < 500">
        <v-icon class="mr-2" :color="color.theme">mdi-account-circle</v-icon>
        <v-toolbar-title :style="'color:' + color.theme" >{{$t('Personalinformation')}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon @click="$emit('closedialog'),$emit('openDrag')" :color="color.theme">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-toolbar dark :color="color.theme" v-else>
        <v-toolbar-title>{{$t('Personalinformation')}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="$emit('closedialog'),$emit('openDrag')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
        <v-card-text class="pa-0" style="height: 100vh">
          <v-container elevation-0 >
            <v-row align="center">
              <v-col cols="12">
                <v-layout row wrap justify-center >
                  <v-flex lg8 xs11 class="mr-4 ml-4">
                    <v-card class="elevation-1">
                      <v-layout justify-center v-if="resolutionScreen < 500">
                        <v-flex lg3 xs6 class="pt-5" offset-xs-4 d-flex justify-center>
                          <v-avatar
                            size="120px"
                            height="120px"
                            v-if="imagelink !== null"
                          >
                            <img :src="myImage">
                          </v-avatar>
                          <v-avatar
                            size="120px"
                            height="120px"
                            v-else
                          >
                            <!-- <img src="https://image.flaticon.com/icons/svg/149/149071.svg"> -->
                            <img src="@/assets/user.png">
                          </v-avatar>
                        </v-flex>
                      </v-layout>
                      <br /> 
                       <!-- <v-layout row wrap justify-center>
                        <v-flex lg10 xs10>
                          <v-divider></v-divider>
                         </v-flex>
                      </v-layout> -->
                      <!-- citizent -->
                      <v-layout  row wrap justify-center>
                        <v-flex lg3 xs11 class="pr-2">
                          <v-card-title>
                            <v-chip pill :color="$vuetify.theme.dark ? '#363636' : '#ECF0F3'" v-if="resolutionScreen < 500">
                              <v-avatar left>
                                <v-icon color="#71B5D8" large>mdi-account-circle</v-icon>
                              </v-avatar>
                              <h3 :style="'color:' + color.theme">{{ $t('CitizenProfile') }}</h3>
                            </v-chip>
                            <span v-else
                              style="font-size: 18px; font-weight: 600;"
                            >{{$t('CitizenProfile')}}</span>
                          </v-card-title>
                          <div v-if="resolutionScreen > 500">
                            <v-avatar
                              size="180px"
                              height="140px"
                              v-if="imagelink !== null"
                            >
                              <img :src="myImage">
                            </v-avatar>
                            <v-avatar
                              size="180px"
                              height="180px"
                              v-else
                            >
                              <!-- <img src="https://image.flaticon.com/icons/svg/149/149071.svg"> -->
                              <img src="@/assets/user.png">
                            </v-avatar>
                          </div>
                        </v-flex>
                        <v-flex lg6 xs11>
                            <v-list v-if="lang_ === 'th'">
                              <v-list-item>
                                <v-list-item-icon><v-icon :color="color.theme">person</v-icon></v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-subtitle>{{$t('name')}} : </v-list-item-subtitle>
                                    <v-list-item-title >{{dataCitizenProfile.first_name_th}}</v-list-item-title>
                                  </v-list-item-content>
                                   <!-- <v-list-item-icon><v-icon>person</v-icon></v-list-item-icon> -->
                                  <v-list-item-content>
                                    <v-list-item-subtitle>{{$t('surname')}} : </v-list-item-subtitle>
                                    <v-list-item-title>{{dataCitizenProfile.last_name_th}}</v-list-item-title>
                                  </v-list-item-content> 
                                </v-list-item>
                            </v-list>
                            <v-list v-else>
                              <v-list-item>
                                <v-list-item-icon><v-icon :color="color.theme">person</v-icon></v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-subtitle>{{$t('name')}} : </v-list-item-subtitle>
                                    <v-list-item-title >{{dataCitizenProfile.first_name_eng}}</v-list-item-title>
                                  </v-list-item-content>
                                   <!-- <v-list-item-icon><v-icon>person</v-icon></v-list-item-icon> -->
                                  <v-list-item-content>
                                    <v-list-item-subtitle>{{$t('surname')}} : </v-list-item-subtitle>
                                    <v-list-item-title>{{dataCitizenProfile.last_name_eng}}</v-list-item-title>
                                  </v-list-item-content> 
                                </v-list-item>
                            </v-list>
                            <v-list>
                              <v-list-item>
                                <v-list-item-icon><v-icon :color="color.theme">email</v-icon></v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-subtitle>{{$t('emailthai')}} : </v-list-item-subtitle>
                                    <v-list-item-title>{{dataCitizenProfile.thai_email}}</v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                            </v-list>
                            <v-list>
                              <v-list-item>
                                <v-list-item-icon><v-icon :color="color.theme">email</v-icon></v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-subtitle>{{$t('email')}} : </v-list-item-subtitle>
                                    <v-list-item-title>{{dataCitizenProfile.email}}</v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                            </v-list>
                            <!-- dataAccountId  business-->
                            <!-- <v-list>
                              <v-list-item>
                                <v-list-item-icon><v-icon :color="color.theme">person_outline</v-icon></v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-subtitle>{{$t('account_id')}} : </v-list-item-subtitle>
                                    <v-list-item-title>{{dataCitizenProfile.account_id}}</v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                            </v-list> -->
                            <!-- <v-list>
                              <v-list-item>
                                <v-list-item-icon><v-icon :color="color.theme">person_outline</v-icon></v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-subtitle>{{$t('user_id')}} : </v-list-item-subtitle>
                                    <v-list-item-title>{{dataCitizenProfile.user_id}}</v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                            </v-list> -->
                            <v-list>
                              <v-list-item>
                                <v-list-item-icon><v-icon :color="color.theme">mdi-account-circle</v-icon></v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-subtitle>{{$t('usernameprofile')}} : </v-list-item-subtitle>
                                    <v-list-item-title>{{dataCitizenProfile.username}}</v-list-item-title>
                                  </v-list-item-content>
                                  <v-btn x-small :color="color.theme" class="white--text" v-if="dataCitizenProfile.username === '' || dataCitizenProfile.username === undefined" @click="fn_opendialog_username()">ตั้งค่า Username</v-btn>
                                </v-list-item>
                            </v-list>
                        </v-flex>
                      </v-layout>
                      <v-layout row wrap justify-center>
                        <v-flex lg10 xs10>
                          <v-divider></v-divider>
                         </v-flex>
                      </v-layout>
                      <!-- business -->
                      <v-layout  row wrap justify-center v-if="account_active.type === 'Business'">
                        <v-flex lg3 xs11 class="pr-2">
                          <v-card-title>
                            <v-chip pill :color="$vuetify.theme.dark ? '#363636' : '#ECF0F3'" v-if="resolutionScreen < 500">
                              <v-avatar left color="#5AB685">
                                <v-icon color="white">apartment</v-icon>
                              </v-avatar>
                              <h3 :style="'color:' + color.theme">{{ $t('BusinessProfile') }}</h3>
                            </v-chip>
                            <span v-else
                              style="font-size: 18px; font-weight: 600;"
                            >{{$t('BusinessProfile')}}</span>
                          </v-card-title>
                        </v-flex>
                        <v-flex lg6 xs11>
                          <v-list>
                              <v-list-item>
                                <v-list-item-icon><v-icon :color="color.theme">business</v-icon></v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-subtitle>{{$t('namebusiness')}} : </v-list-item-subtitle>
                                    <v-list-item-title v-if="lang_ === 'th'">{{account_active.business_info.first_name_th}}</v-list-item-title>
                                    <v-list-item-title v-else>{{account_active.business_info.first_name_eng}}</v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                            </v-list>
                            <!-- <v-list>
                              <v-list-item>
                                <v-list-item-icon><v-icon :color="color.theme">mdi-account-multiple-outline</v-icon></v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-subtitle>{{$t('account_id')}} : </v-list-item-subtitle>
                                    <v-list-item-title>{{dataAccountId}}</v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                            </v-list> -->
                            <v-list>
                              <v-list-item>
                                <v-list-item-icon><v-icon :color="color.theme">mdi-smart-card</v-icon></v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-subtitle>{{$t('TaxID')}} : </v-list-item-subtitle>
                                    <v-list-item-title  >{{account_active.business_info.taxid}}</v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-flex>
                      </v-layout>
                      <v-layout row wrap justify-center>
                        <v-flex lg10 xs10>
                          <v-divider></v-divider>
                         </v-flex>
                      </v-layout>
                      <!-- ตั้งค่า default business -->
                        <v-layout  row wrap justify-center>
                        <v-flex lg3 xs11  class="pr-2">
                          <v-card-title>
                            <v-chip pill :color="$vuetify.theme.dark ? '#363636' : '#ECF0F3'" v-if="resolutionScreen < 500">
                              <v-avatar left color="#E8A33F">
                                <v-icon color="white">business</v-icon>
                              </v-avatar>
                              <h3 :style="'color:' + color.theme">{{ $t('settingdefaultbusiness') }}</h3>
                            </v-chip>
                            <span v-else
                              style="font-size: 18px; font-weight: 600;"
                            >{{$t('settingdefaultbusiness')}}</span>
                          </v-card-title>
                        </v-flex>
                        <v-flex lg6 xs11>
                            <v-list>
                              <v-list-item>
                                  <v-list-item-icon><v-icon class="pt-1" :color="color.theme">business</v-icon></v-list-item-icon>
                                    <v-list-item-content v-if="lang_ === 'th'">
                                      <v-autocomplete
                                        v-model="Business_default"
                                        :items="listdropdown"
                                        item-text="first_name_th"
                                        item-value="business_id"
                                        :label="datalogindefault.first_name_th === 'citizen' ? 'ผู้ใช้งานทั่วไป' : datalogindefault.first_name_th"
                                        dense
                                        @change="fn_settingdefaultbusiness"
                                        outlined
                                      ></v-autocomplete>
                                  </v-list-item-content>
                                  <v-list-item-content v-else>
                                      <v-autocomplete
                                        v-model="Business_default"
                                        :items="listdropdown"
                                        item-text="first_name_eng"
                                        item-value="business_id"
                                        :label="datalogindefault.first_name_eng === 'citizen' ? 'Personal' : datalogindefault.first_name_eng"
                                        dense
                                        @change="fn_settingdefaultbusiness"
                                        outlined
                                      ></v-autocomplete>
                                  </v-list-item-content>
                              </v-list-item>
                            </v-list>
                        </v-flex>
                      </v-layout>
                      <v-layout row wrap justify-center>
                        <v-flex lg10 xs10>
                          <v-divider></v-divider>
                         </v-flex>
                      </v-layout>
                      <!-- ข้อมูลหน่วยความจำ -->
                      <v-layout row wrap justify-center v-if="resolutionScreen < 500">
                        <v-flex lg3 xs11  class="pr-2">
                          <v-card-title>
                            <v-chip pill :color="$vuetify.theme.dark ? '#363636' : '#ECF0F3'">
                              <v-avatar left color="#3B82F4">
                                <v-icon color="white">mdi-cloud-print</v-icon>
                              </v-avatar>
                              <h3 :style="'color:' + color.theme">{{ $t('memoryinformation') }}</h3>
                            </v-chip>
                          </v-card-title>
                        </v-flex>
                        <v-flex lg6 xs11>
                          <v-list dense>
                            <v-list-item class="pt-0">
                              <v-list-item-icon>
                                <v-icon :color="color.theme">mdi-cloud</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-subtitle class="caption font-weight-light">
                                  <v-layout>{{ $t('toolbar.mydrive') }}
                                    <v-spacer></v-spacer>
                                    <strong class="caption font-weight-light">
                                      {{ percentageStorage }} %
                                    </strong>
                                  </v-layout>
                                  <v-progress-linear
                                    height="7"
                                    rounded
                                    :color="percentagestoage_setcolor"
                                    :value="percentageStorage"
                                  >
                                  </v-progress-linear>
                                  {{ dataStorageUsage }} in {{ dataStorageMax }}
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                          <v-list-item v-if="account_active.type === 'Citizen'">
                            <v-list-item-icon><v-icon :color="color.theme">date_range</v-icon></v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>{{getdates}} {{$t('days')}}</v-list-item-title>
                              <v-list-item-subtitle>{{$t('Numberofdaystodelete')}}</v-list-item-subtitle>
                              <v-select
                                :label="$t('default') === 'en' ? 'Date Default' : 'ไม่กำหนด'"
                                class="pt-5"
                                dense
                                v-model="date"
                                @change="setdate(date)"
                                outlined
                                :items="$t('default') === 'en' ? listdate_en : listdate"
                              >
                              </v-select>
                            </v-list-item-content>
                          </v-list-item>
                        </v-flex>
                      </v-layout>

                      <v-layout  row wrap justify-center v-else>
                        <v-flex lg3 xs11  class="pr-2">
                          <v-card-title>
                            <span
                              style="font-size: 18px; font-weight: 600;"
                            >{{ $t('memoryinformation') }}</span>
                          </v-card-title>
                        </v-flex>
                        <v-flex lg6 xs11>
                          <v-list>
                            <v-list-item>
                              <v-list-item-icon><v-icon :color="color.theme">cloud</v-icon></v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>{{dataStorageUsage}}</v-list-item-title>
                                  <v-list-item-subtitle>{{$t('dataStorageUsage')}}</v-list-item-subtitle>
                                </v-list-item-content>
                                <v-divider vertical></v-divider>
                                <v-list-item-content  class="pl-4">
                                  <v-list-item-title>{{dataStorageMax}}</v-list-item-title>
                                  <v-list-item-subtitle>{{$t('dataStorageMax')}}</v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                          </v-list>
                          <v-list v-if="account_active.type === 'Citizen'">
                            <v-list-item>
                                <v-list-item-icon><v-icon :color="color.theme">date_range</v-icon></v-list-item-icon>
                                  <v-list-item-content>
                                    <!-- {{changedates}} {{changedates === '' ? '':'Days'}} -->
                                    <v-list-item-title>{{getdates}} {{$t('days')}}</v-list-item-title>
                                    <v-list-item-subtitle>{{$t('Numberofdaystodelete')}}</v-list-item-subtitle>
                                    <v-select
                                      :label="$t('default') === 'en' ? 'Date Default' : 'ไม่กำหนด'"
                                      class="pt-5"
                                      dense
                                      v-model="date"
                                      @change="setdate(date)"
                                      outlined
                                      :items="$t('default') === 'en' ? listdate_en : listdate"
                                    >
                                    <!-- <template v-slot:item="{ item }">{{ $t(item.name) }}</template>
                                    <template v-slot:selection="{ item }">{{ $t(item.name) }}</template> -->
                                    </v-select>
                                </v-list-item-content>
                            </v-list-item>
                          </v-list>
                          <v-list>
                            <v-list-item>
                              <v-list-item-subtitle class="text-center">
                                <v-progress-linear
                                  height="20"
                                  rounded
                                  :color="percentagestoage_setcolor"
                                  :value="percentageStorage"
                                >
                                  <span style="color:white">{{percentageStorage}}%</span>
                                </v-progress-linear>
                                <v-chip class="ma-5"><v-icon class="pa-1 pt-0 pb-0">cloud</v-icon>{{ dataStorageUsage }} in {{ dataStorageMax }}</v-chip>
                              </v-list-item-subtitle>
                            </v-list-item>
                          </v-list>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <dialogsetusername
      :show="opendialogsettingusername"
      @closedialog="opendialogsettingusername = false"
      @reload_user="reload_page()"
    ></dialogsetusername>
  </v-row>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import gbfsizeDataConverter from "@/globalFunctions/sizeDataConverter";
import VueCookies from "vue-cookies";
import axios from "axios";
const dialogsetusername = () => import("../optional/dialog-setting-username.vue");
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000
});
export default {
  data() {
    return {
      opendialogsettingusername:false,
      citizen_info: {},
      id_biz:'',
      lang_:'',
      checkdata:'',
      default_databiz:'',
      dropdown:[],
      listdropdown:[],
      datalogindefault:[],
      date:'',
      Business_default: '',
      changedates:'',
      getdates:[],
      total:'',
      showac: false,
      showuser: false,
      imagelink:'',
      listdate:["ไม่กำหนด","7 วัน","30 วัน","90 วัน"],
      listdate_en:["DateDefault","7 Days","30 Days","90 Days"],
      chceksetlogindefault: false
      // listdate:[{name:"DateDefault",text:"DateDefault"},{name:"7"},{name:"30"},{name:"90"}]
    };
  },
  props: ["show"],
  components: {
    dialogsetusername
  },
  methods: {
    reload_page(){
      window.location.reload(true);
    },
    fn_opendialog_username(){
      this.opendialogsettingusername = true;
    },
    async fn_settingdefaultbusiness(item){
      console.log("item",item);
      var payload = {
        business_id:this.Business_default,
        type:"account_id",
        data_id: this.dataCitizenProfile.account_id   
        };
        console.log(" fn_settingdefaultbusiness payload",payload);
      let auth = await gbfGenerate.generateToken();
      this.axios
        .post(
          process.env.VUE_APP_SERVICE_ADMIN + "/api/update_login_defualt",payload ,
          {  headers: { Authorization: auth.code } }
        ).then(response => {
          
            if (response.data.status === "OK") {
              console.log("response",response);
              this.loadprogress = false;
              Toast.fire({
                icon: "success",
                title: this.$t('settinfdefaulesuccess')
              });
              this.chceksetlogindefault = true;
              this.fn_getlistbusiness()
              this.get_profile()

            }else{
               Toast.fire({
              icon: "error",
              title: response.data.errorCode + ': ' + response.data.errorMessage,
            });
            }
          }).catch(error => {
          
          console.log(error);
          Toast.fire({
            icon: "error",
            title: this.$t("toast.cannotconnectonebox")
          });
        });
    },
    async fn_check_business_in_domain(){
      console.log("$router",this.$route);
      let auth = await gbfGenerate.generateToken();

      let payload = {
        domain: "https://uatbox-dedicate.one.th",
      }
      console.log("payload121212",payload);
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_AUTHORIZE_API + "/api/check_business_in_domain",
          payload, {
            headers: { Authorization: auth.code },
          }
        )
        .then((response) => {
          if (response.data.status == "OK") {
            console.log("response.data_fn_check_business_in_domain",response.data);
            this.businessIndomain = response.data.data
          } else {
            console.log("response error",response.data);
          }
        })
        .catch((error) => {
          console.log("error gettamplate search", error);
        });
    },
    async fn_getlistbusiness(){
     await this.fn_check_business_in_domain();
      this.listdropdown = [],
      this.dropdown = [];
      this.datalogindefault=[];
        var payload = {
        user_id : this.dataCitizenProfile.user_id,        
        };
        console.log("payload",payload);

        await this.axios
        .post(
          process.env.VUE_APP_SERVICE_AUTHORIZE_API + "/api/get_login_default_detail",payload
        ).then(response => {
          
            if (response.data.status === "OK") {
              console.log("response",response);
              this.datalogindefault = response.data.login_default;
              this.id_biz = response.data.login_default.login_default;
              console.log("id_biz", this.id_biz);
              console.log("dataAccountId", this.dataAccountId);
              //  ผู้ใช้งานทั่วไป
              this.citizen_info["account_id"] = this.dataAccountId;
              this.citizen_info["branch_no"] = null;
              this.citizen_info["business_id"] = "";
              this.citizen_info["first_name_eng"] = "Personal";
              this.citizen_info["first_name_th"] = "ผู้ใช้งานทั่วไป";
              // this.listdropdown.push(this.citizen_info);
              //  บริษัทอื่น
              this.datalogindefault = response.data.login_default;
              if (this.chceksetlogindefault === false) {
                this.Business_default = response.data.login_default.login_default;
              }
              // this.dataloginbusiness = [];
              // this.dataloginbusiness.push({
              //   business_id: "f04d153beeae498c351a4acd83c9989a",
              //   tax_id: "0107559000061",
              //   first_name_eng: "SiamEast Solutions Public Company Limited",
              //   first_name_th: "สยามอีสต์ โซลูชั่น จำกัด (มหาชน)",
              //   branch_no: "00001"
              // });
              
              for(let i = 0;i<response.data.business_dropdown.length;i++){
                for(let y = 0; y < this.businessIndomain.length; y++){
                  if (response.data.business_dropdown[i]["first_name_eng"] !== "citizen") {
                      if(response.data.business_dropdown[i]["business_id"] === this.businessIndomain[y].business_id){
                          let datadropdown ={};
                        datadropdown["account_id"] = response.data.business_dropdown[i]["account_id"];
                        datadropdown["branch_no"] = response.data.business_dropdown[i]["branch_no"];
                        datadropdown["business_id"] = response.data.business_dropdown[i]["business_id"] !== '' ? response.data.business_dropdown[i]["business_id"] : " ";
                        datadropdown["first_name_eng"] = response.data.business_dropdown[i]["first_name_eng"];
                        datadropdown["first_name_th"] = response.data.business_dropdown[i]["first_name_th"] == 'citizen' ? 'ผู้ใช้งานทั่วไป' : response.data.business_dropdown[i]["first_name_th"];;
                        this.listdropdown.push(datadropdown)
                      }
                  }
                }
              }
              // this.checkdata = this.listdropdown.findIndex(v => v.first_name_th === this.datalogindefault.first_name_th);
              // this.default_databiz = this.listdropdown[this.checkdata].first_name_th
              // console.log("data++",this.default_databiz);
              // console.log(this.listbusinsess_th);
              this.loadprogress = false;
              this.chceksetlogindefault = false;
              // console.log("this.dropdown",this.dropdown);
              // console.log("this.datalogindefault",this.datalogindefault.first_name_th);

            }else{
               Toast.fire({
              icon: "error",
              title: response.data.errorCode + ': ' + response.data.errorMessage,
            });
            }
          }).catch(error => {
          
          console.log(error);
          Toast.fire({
            icon: "error",
            title: this.$t("toast.cannotconnectonebox")
          });
        });
    },
    async get_profile() {
      this.loadprogress = true;
      let authorize;
      if (sessionStorage.getItem("checklogin") == "false") {
        if (sessionStorage.getItem("token") == "null") {
          let user_id = CryptoJS.AES.decrypt(
            sessionStorage.getItem("user_id"),
            CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY),
            { mode: CryptoJS.mode.ECB }
          );
          user_id = window.atob(user_id.toString(CryptoJS.enc.Utf8));
          // authorize = { user_id: sessionStorage.getItem("user_id") };
          authorize = { user_id: user_id };
          // let biz_user_id = sessionStorage.getItem("biz_active");
          // authorize = { user_id:  biz_user_id.user_id };
        } else {
          // authorize = { accesstoken: VueCookies.get("token") };
          authorize = {
            accesstoken: window.atob(sessionStorage.getItem("token")),
          };
        }
      } else {
        if (VueCookies.get("token") === "" || VueCookies.get("token") == null) {
          let user_id = CryptoJS.AES.decrypt(
            VueCookies.get("user_id"),
            // localStorage.getItem("user_id"),
            CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY),
            { mode: CryptoJS.mode.ECB }
          );
          user_id = window.atob(user_id.toString(CryptoJS.enc.Utf8));
          authorize = { user_id: user_id };
          // let biz_user_id = sessionStorage.getItem("biz_active");
          // authorize = { user_id:  biz_user_id.user_id };
        } else {
          // authorize = { accesstoken: VueCookies.get("token") };
          authorize = { accesstoken: window.atob(VueCookies.get("token")) };
          // authorize = { accesstoken: sessionStorage.getItem("token") };
        }
      }
      // เอาข้อมูล ใน api getprofile มาใส่ใน payload เพื่อส่งค่า payload ไปยัง goto_biz เพื่อไปยิง login_business
      var result = await axios.post(
        process.env.VUE_APP_SERVICE_AUTHORIZE_API + "/api/getprofile",
        authorize
      );
      console.log("result", result);
      let payload = {
        taxid: result.data.result.login_default.id_card_num,
        accesstoken: result.data.result.accesstoken,
        biz_id: result.data.result.login_default.id,
        branch_no: result.data.result.login_default.branch_no || "00000",
      };
      console.log("payload", payload);
      // Toast.fire({
      //   icon: "success",
      //   title: this.$t("settinfdefaulesuccess"),
      // });
      console.log("goto_biz");
      this.goto_biz(payload);
    },
    // ส่งค่า payload เพื่อไปยิง api gotobiz_business เพื่อไปหน้าไดร์ฟบริษัทที่เลือก
    async goto_biz(payload) {
      // กรณีเลือก ผู้ใช้งานทั่วไป
      if (this.id_biz === "") {
        console.log("gotobiz_citizen", this.id_biz);
        this.$store.dispatch("switchaccount_citizen")
        this.$router.push("/mydrive");
        this.$emit("closedialog");
        // this.Business_default = '';
        // this.$emit('reload')
      } 
      // กรณีเลือก บริษัท
      else {
        console.log("gotobiz_business", this.id_biz);
       await this.$store.dispatch("switchaccount_business", payload).then((msg) => {
          console.log("msg", msg.status);
          if (msg.status === "Business Access Granted") {
            console.log("ok");
            this.$router.push({
              path:
                "/directory/" +
                this.dataAccountActive.business_info.my_folder_id ,
            });
            // this.$router.replace({
            //   name: "directory",
            //   params: { id: this.dataAccountActive.business_info.my_folder_id },
            // });
          }
        });
        this.$emit("closedialog");
        this.$emit("reload");
        // this.Business_default = '';
      }
    },
   async loaddata(){
     let payload
     payload={
       account_id:this.dataCitizenProfile.account_id
     }
     console.log(payload);
     let auth = await gbfGenerate.generateToken();
     await this.axios
        .post(
          process.env.VUE_APP_SERVICE_UPDATE_FILE + "/api/check/datetime_delete_files",
          payload,{headers: { Authorization: auth.code },}
        ).then(response =>{
          if(response.data.status === "OK"){
            this.getdates = response.data.result[0].number_of_days
            console.log(this.getdates);
          }
        })
   },
   async changedatedelete(){
      let payload
      payload ={
        account_id:this.dataCitizenProfile.account_id,
        date_time:this.changedates
      }
      console.log("payload",payload);
      let auth = await gbfGenerate.generateToken();
     await this.axios
        .post(
          process.env.VUE_APP_SERVICE_UPDATE_FILE + "/api/update/datetime_delete_files",
          payload,{headers: { Authorization: auth.code },}
        ).then(response =>{
          if(response.data.status === "OK"){
            console.log(response);
            this.loaddata()
          }
        })
    },
    setdate(val){
      this.changedates= ""
      console.log("val",val);
      if(val === "DateDefault" || val === "ไม่กำหนด"){
        this.changedates = ""
      }else if(val === "7 Days" || val === "7 วัน"){
        this.changedates = "7"
      }else if(val === "30 Days" || val === "30 วัน"){
        this.changedates = "30" 
      }else if(val === "90 Days" || val === "90 วัน"){
        this.changedates = "90"
      }
      console.log("changedates",this.changedates);
       this.changedatedelete()
    },
    image(){
     this.imagelink=this.dataCitizenProfile.picture
    }    
  },
  computed: {
    ...mapState(["username", "authorize", "account_active","color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataStorageMax",
      "dataStorageUsage"
    ]),
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    shower: {
      get() {
        if (this.show === true) {
          this.image()
          this.loaddata()
          this.fn_getlistbusiness()
          this.$emit('closeDrag')
          this.lang_ = localStorage.getItem('lang')
        }
        return this.show;
      }
    },
    myImage() {
    	return `data:image/png;base64, ${this.imagelink}`
    },
    percentageStorage() {
      let _dataStorageMax = this.dataStorageMax.split(" ");
      let _dataStorageUsage = this.dataStorageUsage.split(" ");

      if (_dataStorageMax[1] === "GB" && _dataStorageUsage[1] === "MB") {
        let max_storage = parseFloat(_dataStorageMax[0]) * 1000;
        let current_storage = parseFloat(_dataStorageUsage[0]);
        // console.log(current_storage)
        // console.log(max_storage)
        let total = (current_storage / max_storage) * 100;
        console.log(total);
        return Math.ceil(total);
      } else if (_dataStorageMax[1] === "GB" && _dataStorageUsage[1] === "KB") {
        let max_storage = parseFloat(_dataStorageMax[0]) * 1000000;
        let current_storage = parseFloat(_dataStorageUsage[0]);
        let total = (current_storage / max_storage) * 100;
        console.log(total);
        return Math.ceil(total);
      } else {
        let max_storage = parseFloat(_dataStorageMax[0]);
        let current_storage = parseFloat(_dataStorageUsage[0]);
        // console.log(current_storage)
        // console.log(max_storage)
        let total = (current_storage / max_storage) * 100;
        console.log(total);
        return Math.ceil(total);
      }
    },
    percentagestoage_setcolor() {
      if (
        Math.ceil(this.percentageStorage) >= 0 &&
        Math.ceil(this.percentageStorage) < 70
      ) {
        return "green";
      } else if (
        Math.ceil(this.percentageStorage) >= 70 &&
        Math.ceil(this.percentageStorage) < 90
      ) {
        return "#ffcc00";
      } else if (Math.ceil(this.percentageStorage) >= 90) {
        return "red";
      }
    },
  }
};
</script>

<style>
#thiscontainer {
  height: calc(50vh - 90px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
}
</style>
